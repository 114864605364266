 $(document).ready(function () {
    $(document).on("scroll", onScroll);



    $('.menu__button').click(function() {
        $('.menu__button').toggleClass('menu__button--active');
        $('.menu__dropdown').toggleClass('menu__dropdown--active');
    });
    // smoothscroll
    $('a[href^="#"]').on('click', function (e) {
        smoothscroll(e, this);
    });

});

function smoothscroll(e, link) {
        e.preventDefault();

        if($(link).hasClass('menu__item')) {
            $('.menu__button').toggleClass('menu__button--active');
            $('.menu__dropdown').toggleClass('menu__dropdown--active');
        }
        
        $(document).off("scroll");
              
        var target = link.hash,
            menu = target;
        $target = $(target);
        if ($target.length == 0) {
            $(location).attr('href', '/' + target);
        } else {
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 90
            }, 500, 'swing', function () {
                // window.location.hash = target;
                $(document).on("scroll", onScroll);
                $(document).scroll();
            });
        }
}


function fixMenu(scrollPos) {
    if (scrollPos > 90) {
        $('#menu').addClass('menu--fixed');
    } else {
        $('#menu').removeClass('menu--fixed');
    }
}

function onScroll(event){
    var scrollPos = $(document).scrollTop();
    fixMenu(scrollPos);
    $('.menu__item').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (
            refElement.position().top <= scrollPos + 92
            // && refElement.position().top + refElement.height() > scrollPos
        ) {
            $('.menu__item--active').removeClass("menu__item--active");
            currLink.addClass("menu__item--active");
        }
        else{
            currLink.removeClass("menu__item--active");
        }
    });
}
function askItem(id) {
    $('#title').val(id);
}


    function openOffer(event, element) {
        offer = $(element).closest('.offer');
        offer.children('.offer__content')
            .first()
            .slideToggle();
    }

 $(document).ready(function(){
     $('#showForgetDataButton').unbind();
     $('#showForgetButton').click(function(e){
         e.preventDefault();
         $('.contact-form__forget-form').toggle();
     })

     $('#showForgetDataButton').click(function(e){
         e.preventDefault();
         $('.contact-form__forgetData-form').toggle();
     })

 })