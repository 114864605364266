 $(document).ready(function () {

    $("#contact-form").submit(function(e) {
        e.preventDefault(); 
        $('#to-form').click();
        item = $(this);
        var url = item.attr('action');
        item.addClass('form--sended');
        $('.rotate').addClass('rotate--active');
        var data = item.serialize()
        $('.form__info').remove();
        $('.form__input').removeClass('form__input--error');
        var featureName = item.attr('data-title');
        $.ajax({
            type: "POST",
            url: url,
            data: data, 
            success: function(data) {
                $('.form__title').after(
                    '<div class="form__info form__info--success">'
                    + 'Pomyślnie wysłano zapytanie!'
                    + '</div>'
                );
                item.removeClass('form--sended');
                $('.rotate').removeClass('rotate--active');
            },
            error: function(data) {
                console.log(data);
                item.removeClass('form--sended');
                $('.rotate').removeClass('rotate--active');
                $.each(data.responseJSON, function(i, item) {
                    target = $('[name="' + i + '"]');
                    if (target.hasClass('form__checkbox')) {
                        target.addClass('form__checkbox--error');
                        target = target.next();
                    } else {
                        target.addClass('form__input--error');
                    }
                        itemClass = 'form__info form__info--error';

                    $.each(item, function(id, text) {
                        error = $('<div />', {
                            "class": itemClass,
                            text: text,
                        })
                        target.after(error);
                    })
                });
                $('#to-form').click();
            }

        });
    });
     $("#contact-form-withdrawal").submit(function(e) {
         e.preventDefault();
         $('#to-form2').click();
         item = $(this);
         var url = item.attr('action');
         item.addClass('form--sended');
         $('.rotate').addClass('rotate--active');
         var data = item.serialize()
         $('.form__info').remove();
         $('.form__input').removeClass('form__input--error');
         var featureName = item.attr('data-title');
         $.ajax({
             type: "POST",
             url: url,
             data: data,
             success: function(data) {
                 $('.form__title').after(
                     '<div class="form__info form__info--success">'
                     + 'Pomyślnie wysłano zapytanie!'
                     + '</div>'
                 );
                 item.removeClass('form--sended');
                 $('.rotate').removeClass('rotate--active');
             },
             error: function(data) {
                 console.log(data);
                 item.removeClass('form--sended');
                 $('.rotate').removeClass('rotate--active');
                 $.each(data.responseJSON, function(i, item) {
                     target = $('[name="' + i + '"]');
                     if (target.hasClass('form__checkbox')) {
                         target.addClass('form__checkbox--error');
                         target = target.next();
                     } else {
                         target.addClass('form__input--error');
                     }
                     itemClass = 'form__info form__info--error';

                     $.each(item, function(id, text) {
                         error = $('<div />', {
                             "class": itemClass,
                             text: text,
                         })
                         target.after(error);
                     })
                 });
                 $('#to-form2').click();
             }

         });
     });
});
